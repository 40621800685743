import React from 'react'
import {useRoutes} from "react-router-dom"
import routes from "./Routes/routes"
export default function App() {
  const router = useRoutes(routes)
  return (
    <div className='w-full fixed h-screen'>
      {router}
    </div>
  )
}

