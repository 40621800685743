import React, { useState, Suspense } from "react";
import axios from "axios";
import { PulseLoader } from "react-spinners"
import { useNavigate } from "react-router-dom";
import { useInterval } from "@uidotdev/usehooks";
import { SelectionTable } from "../Components/SelectionTable/SelectionTable";
const Header = React.lazy(() => import("../Components/Mega/Header/Header").then(module => ({ default: module.Header })));
const Table = React.lazy(() => import("../Components/Mega/Table/Table").then(module => ({ default: module.Table })));
const Order = React.lazy(() => import("../Components/Mega/Order/Order").then(module => ({ default: module.Order })));
const TradeBuyAndSell = React.lazy(() => import("../Components/Mega/TradeBuyAndSell/TradeBuyAndSell").then(module => ({ default: module.TradeBuyAndSell })));
const TableListTrade = React.lazy(() => import("../Components/Mega/TableList/TableListTrade").then(module => ({ default: module.TableListTrade })));

export const Mega = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userText, setUserText] = useState(null);
  const [market, setMarket] = useState(null);
  const [userTradeSymbol, setUserTradeSymbol] = useState(null);
  const [userOrderTrade, setUserOrderTrade] = useState(null);
  const [hadUserAll, setHadUserAll] = useState(null);
  const [nameSymbol, setNameSymbol] = useState(null);
  const [symbol, setSymbol] = useState({ name: 'انس', symbol: "XAUUSD" });
  const [loading, setLoading] = useState(Array(12).fill(false));
  const navigate = useNavigate();
  const axiosData = async () => {
    const token = localStorage.getItem('token')
    if (!token) {
      console.error('Token is missing, redirecting to home.');
      return navigate("/login");
    }
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    try {
      const megaData = await axios.post(`${process.env.REACT_APP_PROXY_KEY}/app/showListTrade/${symbol.symbol}`);
      const data = megaData.data;
      if (data === 'not token') {
        return navigate("/login");
      }
      if (data.length <= 0 || data === 0 || data === false) {
        setIsLoading(true);
        return;
      }
      setUserText(data.textUser);
      setMarket(data.market);
      setUserTradeSymbol(data.userTradeSymbol);
      setUserOrderTrade(data.userOrderTrade);
      setNameSymbol(data.symbolAll);
      setHadUserAll(data.userAllHadTrade);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      return navigate("/login")
    }
  }

  useInterval(() => {
    axiosData();
  }, 1000);



  return (
    <div className="w-full overflow-x-hidden overflow-y-auto h-screen ">
      <Suspense fallback={<div />} >
        <Header />
      </Suspense>
      {!isLoading ? (
        <>
          <Suspense fallback={<SelectionTable />}>
            <Table userText={userText} market={market} nameSymbol={nameSymbol} symbol={symbol} setSymbol={setSymbol} />
          </Suspense>
          <Suspense fallback={<div />} >
            <Order hadUserAll={hadUserAll} symbol={symbol} loading={loading} setLoading={setLoading} />
          </Suspense>
          <Suspense fallback={<div />} >
            <TradeBuyAndSell market={market} symbol={symbol} loading={loading} setLoading={setLoading} />
          </Suspense>
          <Suspense fallback={<div />} >
            <TableListTrade userTradeSymbol={userTradeSymbol} setUserTradeSymbol={setUserTradeSymbol} userOrderTrade={userOrderTrade} setUserOrderTrade={setUserOrderTrade} symbol={symbol} loading={loading} setLoading={setLoading} />
          </Suspense>
        </>
      ) : (
        <div className="flex justify-center items-center w-screen h-screen">
          <PulseLoader color="#0f172a" loading margin={12} size={40} />
        </div>
      )}
    </div>
  );
}
