import React from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
export const PageNotFound = () => {
  const navigate = useNavigate();
  const handleNavigate = () => navigate("/");
  return (
    <div className="" >
      <div className="big_text text">
        <h1>
          4<span className="color_blue">0</span>4
        </h1>
        <p className="text-white font-bold text-lg" >
          <button
            className="bg-[#1976D2] rounded-xl ml-4 px-6 text-white hover:shadow-lg py-2"
            onClick={handleNavigate}
          >
            ورود
          </button>
          ورود به مگاسناتور
        </p>
      </div>
      <div className="small_texts">
        <p>404</p>
        <p>404</p>
        <p className="text_3">404</p>
        <p className="text_4">404</p>
        <p className="text_5">404</p>
        <p className="text_6">404</p>
        <p className="text_7">404</p>
        <p className="text_8">404</p>
        <p className="text_9">404</p>
        <p className="text_10">404</p>
        <p className="text_11">404</p>
        <p>404</p>
        <p className="text_12">404</p>
        <p className="text_13">404</p>
      </div>
    </div>
  );
};
