import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
export const SelectionTable = () => {
    return (
        <SkeletonTheme baseColor="#0f172a" highlightColor="#1e293b">
            <section className="bg-gradient-to-tr from-slate-950  to-slate-800/95 px-2 h-[700px]  w-full rounded-xl mt-20">
                <div className="w-full divide-y divide-neutral-700 flex flex-col">
                        <Skeleton count={14} height={40} />
                </div>
            </section>
        </SkeletonTheme>
    )
}