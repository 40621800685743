import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom"

// document.addEventListener('keydown', function (event) {
//     const preventKeys = ['F12', 'I', 'J', 'U'];
  
//     if (event.key === 'F12' ||
//       (event.ctrlKey && event.shiftKey && preventKeys.includes(event.key)) ||
//       (event.metaKey && event.altKey && event.keyCode === 73)) {
//       event.preventDefault();
//     }
//   });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<BrowserRouter><App /></BrowserRouter>);